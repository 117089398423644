import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.locale('zh-cn');

export const toHumanTime = (date: string) => {
  return dayjs(date).format('YYYY 年 MM 月 DD 日');
};

// 2024-10-07T07:47:56.000Z
export const toQueryTime = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
};

export function timeFromNow(date: string): string {
  return dayjs(date).fromNow();
}

export const timeFrom = (date: string, from: Dayjs) => {
  return dayjs(date).from(dayjs(from));
};

export const toTodayTime = (date: string) => {
  return dayjs(date).format('HH:mm');
};

export const daysUntil = (date: string): number => {
  const now = dayjs();
  const targetDate = dayjs(date);
  return targetDate.diff(now, 'day');
};
